import Vue from 'vue';
import Vuex from 'vuex';
import user from './User';
import getters from './getters';
import mutations from './mutations';

Vue.use(Vuex)

// const mutations = {
//     CATEGORY_LIST (state, categoryList) {
//         console.log("产品分类",categoryList)
//         state.categoryList = categoryList
//     }
// }

// const actions = {
//     categoryList ({commit}) {
//         reqCategoryList().then(res => commit("CATEGORY_LIST", res.data))
//     }
// }

export default new Vuex.Store({
    getters,
    // actions,
    mutations,
    modules: {
        user,
    }
})